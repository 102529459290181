@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pincode-input-container {
  @apply w-full;
  @apply grid;
  @apply grid-cols-5;
  @apply justify-items-center;
  @apply content-center; 
}
 
.pincode-input-container .pincode-input-text { 
  @apply rounded-md; 
  @apply mx-auto;
  @apply text-xl;
}

.pincode-input-container .pincode-input-text:focus {
  @apply border-blue-500;
  
}


@media print { 
    @page {
      size: auto;   /* auto is the initial value */
      margin: 0;  /* this affects the margin in the printer settings */ 
      max-width: 70mm !important;
    }
    html, body, body *, .print #printbuttons {
      visibility: hidden; 
    }
    main, main > div > div > div  {
      margin: 0 !important;
      padding: 0 !important;
    }
    .print {   
      visibility: visible; 
      font-size: 12px;
      left: 0;
      top: 0;  
      background: white;
      border: none;
      outline: none;
      padding: 0 !important;
      margin-left: 5mm !important;
      margin-top:5mm !important;
      margin-bottom: 20mm !important;
      padding-bottom: 80px !important; 
      max-width: 70mm !important;  
    }
    .print * {
      visibility: visible;  
    }
    #printbuttons {
      visibility: hidden; 
      display: none;
    }
   
}
