.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.scrollbar::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

}
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(231, 231, 231, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
.scrollbar::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #bbbbbb;
  /*outline: 1px solid slategrey;*/
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


